import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useCallback, useState } from "react";
import { phoneMask } from "../../Utils/ValidationsMasks";
import SweetAlert from "../../Utils/SweetAlert";
import { Api } from "../../Utils/AxiosBiometria";

const VerificarNumero = ({ hash, setShowTerms }) => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    if (phone === "" || (phone !== "" && phone.length < 15)) {
      setError(true);
      SweetAlert.mixin({
        icon: "error",
        title:
          phone !== "" && phone.length
            ? "Número de celular inválido!"
            : "Não é possível seguir sem informar o número de celular!",
      });
    } else {
      setError(false);
      setLoading(true);
      Api.post(`/biometria-registros/celular_motorista`, {
        hash_token: hash,
        numero_celular: `55${phone?.replace(/\D/gi, "")}`,
      })
        .then((res) => {
          if (res.data.success) {
            SweetAlert.mixin({
              icon: "success",
              title: "Número de celular confirmado!",
            });
            setShowTerms(true);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            SweetAlert.small({
              timer: 3000,
              type: "error",
              title: error?.response?.data?.message || "Ocorreu um erro!",
              cb: () => {
                // window.location.href = "https://www.dataprove.com.br";
                return;
              },
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [phone, hash]);

  return (
    <div className="">
      <Row className="px-3 d-flex flex-column align-items-center justify-content-center mt-5">
        <Col className="p-0">
          <Form.Group controlId="email">
            <Form.Label
              className={error ? "text-danger mb-1" : "text-dark mb-1"}
            >
              <div className="d-flex flex-column flex-md-row">
                <span>Prezado(a) Motorista, </span>
                <span className="d-flex ml-md-2">
                  digite o número do seu celular:
                  <span className="text-danger">*</span>
                </span>
              </div>
            </Form.Label>
            <Form.Control
              placeholder="(XX) XXXXX-XXXX"
              className="shadow-sm"
              name="celular"
              value={phoneMask(phone)}
              onChange={(e) => setPhone(phoneMask(e.target.value))}
              maxLength={15}
              required
              isInvalid={error}
              size="lg"
            />
            {error && (
              <p className="text-danger" type="invalid">
                Campo Obrigatório!
              </p>
            )}
          </Form.Group>
        </Col>
        <Button size="lg" onClick={handleSubmit}>
          {(loading && (
            <>
              <Spinner animation="grow" role="status" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>{" "}
              Aguarde...
            </>
          )) ||
            "Enviar!"}
        </Button>
      </Row>
    </div>
  );
};

export default VerificarNumero;
