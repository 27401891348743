import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import RecordRTC from "recordrtc";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import * as faceapi from "face-api.js";

import logo from "../../Assets/images/Logos/logo_dataprove.png";

import "./styles.css";

const FaceId = ({ setStep }) => {
  const [message, setMessage] = useState("Acessando câmera...");
  const [countdown, setCountdown] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const [totalAttempts, setTotalAttempts] = useState(1);

  const navigate = useNavigate();

  const videoRef = useRef(null);
  const recorderRef = useRef(null);
  const faceDetectionInterval = useRef(null);
  const isFaceDetectedRef = useRef(false);
  const attemptsRef = useRef(1);

  const translateError = (error) => {
    const translations = {
      "No video file uploaded": "Nenhum arquivo de vídeo enviado.",
      "Uploaded file is not a valid video":
        "O arquivo enviado não é um vídeo válido.",
      "Video file exceeds the maximum allowed size of 5 MB":
        "O arquivo de vídeo excede o tamanho máximo permitido de 5 MB.",
      "No significant movement or too much detected in the video":
        "Sem movimento significativo ou muito movimento detectado no vídeo.",
      "No human face detected": "Nenhum rosto humano detectado.",
      "Internal Server Error": "Erro interno do servidor.",
      "Face detected as not real.": "Face detectada não é real.",
    };
    return translations[error] || "Erro desconhecido. Tente novamente.";
  };

  useEffect(() => {
    const loadModels = async () => {
      setMessage("Carregando a detecção facial...");
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
    };

    const getVideoStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "user" },
        });
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
          setTimeout(() => startCountdown(), 2000);
        };
      } catch (error) {
        handleCameraError(error);
      }
    };

    loadModels().then(getVideoStream);

    return () => stopVideoStream();
  }, []);

  const getSupportedMimeType = () => {
    if (MediaRecorder.isTypeSupported("video/mp4;codecs=avc1")) {
      return "video/mp4;codecs=avc1"; // Compatível com iOS
    }

    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
      return "video/webm;codecs=vp8"; // Compatível com Android
    }

    return "";
  };

  const handleCameraError = (error) => {
    const errorMessage =
      error.name === "NotAllowedError"
        ? "Permissão de câmera negada. Por favor, habilite nas configurações do navegador."
        : error.name === "NotFoundError"
        ? "Nenhuma câmera encontrada."
        : "Erro ao acessar a câmera. Tente novamente.";

    setMessage(errorMessage);
  };

  const startCountdown = () => {
    if (isFinished) return;

    let timeLeft = 3;

    setCountdown(timeLeft);
    setMessage("Preparando gravação...");

    const timer = setInterval(() => {
      timeLeft -= 1;

      setCountdown(timeLeft);

      if (timeLeft <= 0) {
        clearInterval(timer);
        setCountdown(null);
        startRecording();
      }
    }, 1000);
  };

  const startRecording = () => {
    const stream = videoRef.current?.srcObject;

    if (!stream) return;

    const mimeType = getSupportedMimeType();

    if (!mimeType) {
      setMessage("Nenhum formato de vídeo suportado pelo navegador.");
      return;
    }

    recorderRef.current = new RecordRTC(stream, {
      type: "video",
      mimeType,
      recorderType: mimeType.includes("mp4")
        ? RecordRTC.MediaStreamRecorder // Usa o MediaStreamRecorder para MP4
        : undefined,
    });

    recorderRef.current.startRecording();

    setMessage("Aproxime o rosto e sorria.");

    monitorFaceDetection(); // Inicia monitoramento de rosto

    setTimeout(() => {
      if (isFaceDetectedRef.current) {
        stopRecording();
      } else {
        discardRecording();
      }
    }, 3000);
  };

  const stopRecording = async () => {
    attemptsRef.current += 1;

    if (!recorderRef.current) return;

    clearInterval(faceDetectionInterval.current); // Limpa o intervalo de detecção de rosto

    recorderRef.current.stopRecording(async () => {
      const blob = recorderRef.current.getBlob();
      await handleRecordingStop(blob, attemptsRef.current);
    });
  };

  const discardRecording = () => {
    clearInterval(faceDetectionInterval.current); // Limpa o intervalo de detecção de rosto

    setMessage("Nenhum rosto detectado. Reiniciando...");

    recorderRef.current?.reset();

    setTimeout(() => startCountdown(), 2000);
  };

  const monitorFaceDetection = () => {
    faceDetectionInterval.current = setInterval(async () => {
      if (!videoRef.current) return;

      const detections = await faceapi.detectSingleFace(
        videoRef.current,
        new faceapi.TinyFaceDetectorOptions({
          inputSize: 160,
          scoreThreshold: 0.5,
        })
      );

      isFaceDetectedRef.current = !!detections; // Atualiza o valor do ref
    }, 500);
  };

  const handleRecordingStop = async (blob, attempts) => {
    console.log({ attempts });
    const formData = new FormData();
    const id_processo = localStorage.getItem("@data-prove-biometria:driverId");

    const fileName = blob.type.includes("mp4") ? "video.mp4" : "video.webm";
    formData.append("video", blob, fileName);
    formData.append("id_processo", id_processo);

    if (attempts > 3) {
      formData.append("easy_biometria", true);
    }

    setMessage("Processando...");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE}/biometria/remota/facial`,
        formData
      );

      setMessage(response.data.message || "Vídeo enviado com sucesso.");
      stopVideoStream();
      setIsFinished(true);
      setIsVideoVisible(true);

      navigate("/sucesso_validacao");
    } catch (error) {
      const errorMessage = translateError(error.response?.data?.message);

      setTotalAttempts((prev) => {
        if (prev >= 4) {
          setMessage("Tentativas excedidas, tente novamente!");

          setTimeout(() => setStep(1), 2000);
        } else {
          setMessage(errorMessage);

          setTimeout(() => startCountdown(), 1000);
        }

        return prev + 1;
      });

      setIsVideoVisible(true);
    }
  };

  const stopVideoStream = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  return (
    <>
      <div className="bodyValidRemota backgroundThemeCnh">
        <div className="header-validacao" style={{ marginBottom: "5px" }}>
          <img style={{ width: 100 }} className="logo" src={logo} alt="" />
        </div>

        <div
          style={{
            textAlign: "center",
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" variant="light" />
          <h3
            style={{ marginBottom: "0px", color: "white", marginLeft: "10px" }}
            className="titleFaceApi"
          >
            {countdown !== null
              ? `O processo está começando em ${countdown}s`
              : message}
          </h3>
        </div>

        <div
          className="app__video"
          style={{
            width: "100%",
            display: isVideoVisible ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            muted
            playsInline
            style={{
              borderRadius: "50%",
              border: "5px solid #4CAF50",
              width: "286px",
              height: "430px",
              objectFit: "cover",
              overflow: "hidden",
              boxShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
            }}
          />
        </div>
        <h4
          style={{ marginTop: "20px", color: "white" }}
          className="titleFaceApi"
        >
          Validação Facial
        </h4>
      </div>
    </>
  );
};

export default FaceId;
